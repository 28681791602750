<template>
  <b-row>

    <!-- Small -->
     

    <!-- Large -->
    <b-col cols="12">
      <b-form-group>
        <v-select  v-if="categories!=null"
          v-model="selected"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="categories" 
          
          class="select-size-lg"
          single
        >
         <template #option="{ title ,description }">
             
             
              <span> {{ title }}</span><br/>
                    <small class="text-muted"> {{ description }} </small>
            </template>
        </v-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store' 
import axios from '@axios'
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  
  props :{
    initialCategories:[]
  },


  methods:{
     updateSelections(a) {
    
    //console.log(a)
  },
         fetchCategories( ) {
      //console.log("  course modes fetch");
      return new Promise((resolve, reject) => {
        axios
          .get('/survey/admin/surveythemes' )
          .then((response)=> { 
           
         
           var data=  response.data;
           var responseData=JSON.parse(  JSON.stringify( data));
          //console.log(responseData.data);
              this.categories=responseData.data;
        if (this.initialCategories==null||this.initialCategories.length==0)
        {
            this.selectedinitialCategories=this.categories[0];
        }
          

         

            }
            )
          .catch(error => reject(error))
      })
    },
  },
  watch: {
  selected: function (newVal,coldVal) {

     this.$emit("selectionChanged", newVal);

  }
},
 created() {
    this.fetchCategories();
 this.selectedinitialCategories=  this.initialCategories 
   
},
  data() {
    return {
      categories:null,
     selectedinitialCategories:null,
      selected: this.initialCategories,
      option: [{ title: 'Square' }, { title: 'Rectangle' }, { title: 'Rombo' }, { title: 'Romboid' }],
    }
  }
   

}
</script>