import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

import router from '@/router'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList(surveyId) {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
  
    { key: 'description',label:'Question', class
    :'cols-2', sortable: true },
    { key: 'questionType',label:'Question Type', sortable: true },
    { key: 'questionAnswers', class
    :'cols-1',label:'Answers', sortable: true },
    { key: 'questionTag',label:'Value', sortable: true },
 
    { key: 'status', sortable: true },
  ]
  const perPage = ref(10)
 
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const totalQuestions = ref(0)
  const currentproccess = ref(0)
  

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalQuestions.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchSurveyQuestions = (ctx, callback) => {

    //console.log("loading questions with Id "+surveyId);
    store
      .dispatch('app-survey-questions/fetchSurveyQuestions',
       {
         id: surveyId,
        q: searchQuery.value,
        l: perPage.value,
        p:currentPage.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      
      },
         
      )
      .then(response => {

       
        const { data ,questioncount } = response.data
           //console.log("resolved Data");;
           //console.log(response.data);
           //console.log( "question count "+questioncount);
           totalQuestions.value=questioncount;
        callback(response.data.data)
        
        
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching question list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const surveyInfo = ref(null)

  const fetchSurvey = (ctx, callback) => {

    var surveyid=  router.currentRoute.query.s; ///router.currentRoute.params.id;
    //console.log(" fetch courses triggered surveyid:"+ surveyid);
   store
     .dispatch('app-survey-questions/fetchSurvey', {
       id:   surveyid,
       q: searchQuery.value,
       l: perPage.value,
       p:currentPage.value,
       perPage: perPage.value,
       page: currentPage.value,
       sortBy: sortBy.value,
       sortDesc: isSortDirDesc.value,
       role: roleFilter.value,
       plan: planFilter.value,
       status: statusFilter.value,
     })
     .then(response => {

      //console.log('incoming survey info');
       const { data ,allresults  } = response.data
          //console.log("resolved survey info Data");;
          //console.log(response.data);
          currentproccess.value=currentproccess.value+1;

          surveyInfo.value=response.data.data;

        
      
       
     })
     .catch((ext) => {
       toast({
         component: ToastificationContent,
         props: {
           title: 'Error fetching survey info',
           icon: 'AlertTriangleIcon',
           variant: 'danger',
           text:ext
         },
       })
     })
 }
 fetchSurvey();
  const draftCourse = (ctx, callback) => {
    store
      .dispatch('app-surveys/draftCourse', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {

       
        const { data  } = response.data
             currentproccess.value=currentproccess.value+1;
        callback(data)
       
        
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error updating course status',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }


  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    //console.log("status "+status);
    if (status ===0) return 'warning'
    if (status === 1) return 'success'
    if (status === 2) return 'secondary'
    return 'primary'
  }
  const resolveUserStatusVariantText = status => {
    if (status ===400) return 'deleted'
    if (status === 1) return 'published'
    if (status === 0) return 'draft'
    return 'primary'
  }

  const resolvePageroute = coursemode => {
    if (coursemode === 'IN-PERSON') return 'course-sessions'
    if (coursemode === 'ONLINE') return 'course-lessons'
    if (coursemode === 'HYBRID') return 'course-lessons'
   
    return 'course-lessons'
  }
  const resolveCourseContent= coursemode => {
    if (coursemode === 'IN-PERSON') return 'Sessions'
    if (coursemode === 'ONLINE') return 'Lectures'
    if (coursemode === 'HYBRID') return 'Lectures'
   
    return 'Lectures'
  }


  const resolveSurveyStatus = status => {
    if (status === 0) return 'draft'
    if (status === 50) return 'ongoing'
    if (status === 100) return 'completed'
    if (status === 200) return 'canceled'
    if (status === 300) return 'terminated'
    return 'unknown'
  }
  const resolveSurveyStatusVariant = status => {
    //console.log("status "+status);
    if (status ===0) return 'light-secondary'
    if (status === 50) return 'light-warning'
    if (status === 100) return 'light-success'
    if (status === 200) return 'light-dark'
    if (status === 300) return 'light-dark'

    return 'primary'
  }


  return {
    currentproccess,
    surveyInfo,
    fetchSurveyQuestions,
    tableColumns,
    perPage,
    currentPage,
    totalQuestions,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserStatusVariantText,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    resolvePageroute,
    resolveCourseContent,
    resolveSurveyStatus,
    resolveSurveyStatusVariant,totalQuestions

  }
}
