<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Create New Question
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(handleQuestionSubmit)"
          @reset.prevent="resetForm"
        >

        

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Question"
        
          >
            <b-form-group
              label="Question"
              label-for="SurveyCode"
            >
           
          <quill-editor
     
     
       height="100"
      :options="snowOption"
       :content="localQuestion.description" 
    @change="onEditorChange($event)"
      @blur="onEditorBlur($event)"
    @focus="onEditorFocus($event)"
    @ready="onEditorReady($event)"
    />


              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

        
    <b-form-group
              label="Comment"
              label-for="SurveyCode"
            >
            <b-form-checkbox
      v-model="localQuestion.allowComment"
      value="true"
    >
      Allow attendees to add  comment
    </b-form-checkbox>

           
            </b-form-group>
 
   <b-form-group
              label="Required"
              label-for="SurveyCode"
            >
            <b-form-checkbox
      v-model="localQuestion.required"
      value="true"
    >
      Attendees can't skip this question
    </b-form-checkbox>

           
            </b-form-group>
          <!-- User Role -->
        

         <b-form-group
              label="Question Tag"
              label-for="user-role"
              :state="questionTag!=null"
            >
               <question-tag-picker
             :initialtag="questionTag"
            :survey-info="surveyInfo"
            :key="surveyChecker"
              @selectionChanged="QuestionTagUpdated"
            />
             
            </b-form-group>
 
            <b-form-group
              label="Question Type"
              label-for="user-role"
              v-if="(surveyInfo!=null&&surveyInfo.surveyTypeKey!='LEADERSHIP')&&(surveyInfo!=null&&surveyInfo.surveyTypeKey!='EVALUATION')"
              :state="selectedCategory!=null"
            >
               <survey-type-picker
             :initialCategories="selectedCategory"
              @selectionChanged="SurveyTypeUpdated"
            />
             
            </b-form-group>
  
           


<div v-if="(surveyInfo!=null&&surveyInfo.surveyTypeKey==='LEADERSHIP')||surveyInfo!=null&&surveyInfo.surveyTypeKey==='EVALUATION'"> 
             <b-form-group
              label="Value & text for  minimum answer"
              label-for="user-role"
              :state="!(localQuestion.questionTypeKey==null||localQuestion.questionTypeKey==0)"
            >

            <b-row>
            
        
       <b-row>
           <b-col cols="3">
  
       <b-form-input
                id="minValue"
                v-model="localQuestion.additionalInfo.min1Value"
             disabled
               placeholder="1"
                trim
              />
    </b-col>
              <b-col>
       <b-form-input
                id="min1Text"
                v-model="localQuestion.additionalInfo.min1Text"
             
               placeholder="Poor"
                trim
              />
    </b-col>
 
           </b-row>

      

 
    <b-row>
           <b-col cols="3">
  
       <b-form-input
                id="min2Value"
                v-model="localQuestion.additionalInfo.min2Value"
                disabled
               placeholder="2"
                trim
              />
    </b-col>
              <b-col>
       <b-form-input
                id="min2Text"
                v-model="localQuestion.additionalInfo.min2Text"
             
               placeholder="Needs Improvement"
                trim
              />
    </b-col>
 
           </b-row>

            <b-row>
           <b-col cols="3">
  
       <b-form-input
                id="min3Value"
                v-model="localQuestion.additionalInfo.min3Value"
                disabled
               placeholder="2"
                trim
              />
    </b-col>
              <b-col>
       <b-form-input
                id="min3Text"
                v-model="localQuestion.additionalInfo.min3Text"
             
               placeholder="Average"
                trim
              />
    </b-col>
 
           </b-row> 

              <b-row>
           <b-col cols="3">
  
       <b-form-input
                id="min4Value"
                v-model="localQuestion.additionalInfo.min4Value"
                disabled
               placeholder="4"
                trim
              />
    </b-col>
              <b-col>
       <b-form-input
                id="min4Text"
                v-model="localQuestion.additionalInfo.min4Text"
             
               placeholder="Good"
                trim
              />
    </b-col>
 
           </b-row>
    <b-row>
           <b-col cols="3">
  
       <b-form-input
                id="min5Value"
                v-model="localQuestion.additionalInfo.min5Value"
                disabled
               placeholder="5"
                trim
              />
    </b-col>
              <b-col>
       <b-form-input
                id="min5Text"
                v-model="localQuestion.additionalInfo.min5Text"
             
               placeholder="Excellent"
                trim
              />
    </b-col>
 
           </b-row>

            </b-row>
            </b-form-group>
        
              
         
</div>
        

<div v-if="localQuestion.questionTypeKey!=null&&localQuestion.questionTypeKey==='MULTIPLE_SELECTION'"> 
      
           
     <div>
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
  <b-form-group
              label="Available Answers"
              label-for="item-name"
            >  
        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in localQuestion.questionAnswers"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
        <b-col
            lg="1"
            md="1"
          >
           

       <b-form-checkbox
        v-model="item.selected"
       value="true"
      class="custom-control-primary"
    >
       
    </b-form-checkbox>

          </b-col>
          <!-- Item Name -->
          <b-col md="9">
          
              <b-form-textarea
                id="item-name"
                type="text"
                v-model="item.answer"
                placeholder="Another answer"
              />
          
          </b-col>

          
 
 

          <!-- Remove Button -->
          <b-col
            lg="2"
            md="2"
          
          >
            
            <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="danger"
      class="btn-icon"       @click="removeItem(index)"
    >
      <feather-icon
                icon="XIcon"
             
              />
    </b-button>

          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
</b-form-group>
      </b-form>
 
    <b-button
     
        v-ripple.400="'rgba(0, 207, 232, 0.15)'"
      variant="flat-info"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add Another Option</span>
    </b-button>
  </div>    
         
</div>
        
<div v-if="localQuestion.questionTypeKey!=null&&localQuestion.questionTypeKey==='SINGLE_SELECTION'"> 
              <div>
     <div>
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
  <b-form-group
              label="Available Answers"
              label-for="item-name"
            >  
        <!-- Row Loop -->
        <b-row
          v-for="(item,index) in  localQuestion.questionAnswers"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
        <b-col
            lg="1"
            md="1"
          >
            <b-form-radio
          v-model="localQuestion.acceptedAnswer"
       :value="item.value"
      class="demo-inline-spacing"
      name="radio-validation"
    >
      
    </b-form-radio>
          </b-col>
          <!-- Item Name -->
          <b-col md="9">
          
              <b-form-textarea
                id="item-name"
                type="text"
                v-model="item.answer"
                placeholder="Another answer"
              />
          
          </b-col>

          
 
 

          <!-- Remove Button -->
          <b-col
            lg="2"
            md="2"
          
          >
            
            <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="danger"
      class="btn-icon"       @click="removeItem(index)"
    >
      <feather-icon
                icon="XIcon"
             
              />
    </b-button>

          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
</b-form-group>
      </b-form>
    </div>
    <b-button
     
        v-ripple.400="'rgba(0, 207, 232, 0.15)'"
      variant="flat-info"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add Another Option</span>
    </b-button>
  </div>
         
</div>

<div v-if="localQuestion.questionTypeKey!=null&&localQuestion.questionTypeKey==='YES_NO_QUESTION'"> 
                 <div>
     <div>
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
  <b-form-group
              label="Choose Correct Answers"
              label-for="item-name"
            >  
        <!-- Row Loop -->
        <b-row
          v-for="(item,index   ) in  localQuestion.questionAnswers "
          :id="item.id"
          :key="item.id"
          ref="row"
        >
        <b-col  lg="1"  md="1">
            <b-form-radio
          v-model="localQuestion.acceptedAnswer"
       :value="index==0?'YES':'NO'"
      class="demo-inline-spacing"  name="radio-validation"  >
    
    </b-form-radio>
          </b-col>
            <b-col  md="9">
            
   {{ item.answer}}
        
          </b-col>
          

      
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
</b-form-group>
      </b-form>
    </div>
  
  </div>
         
         
</div>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
             {{ localQuestion!=null&&localQuestion.id!=null||localQuestion.id!=undefined?'Update':'Add'}} 
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
BFormCheckbox,BFormTextarea,BFormRating, BRow, BCol,  BSidebar, BForm, BFormGroup, BFormInput,
  BButton,  BFormRadioGroup,BFormRadio,
    BFormInvalidFeedback,
    BFormValidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref,toRefs,watch,inject  } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import router from '@/router'

import { quillEditor } from 'vue-quill-editor'
import SurveyTypePicker from '../components/SurveyQuestionTypePicker.vue'
import QuestionTagPicker from '../components/QuestionTagPicker.vue'
import SurveyThemePicker from '../components/SurveyThemePicker.vue'
 
import { heightTransition } from '@core/mixins/ui/transition'
export default {
  components: {
   BFormCheckbox,BFormTextarea,BFormRating, BRow, BCol,  BSidebar, BForm, BFormGroup, BFormInput,
  BButton,  BFormRadioGroup,BFormRadio,
    BFormInvalidFeedback,
    BFormValidFeedback,
    vSelect,
SurveyTypePicker,
QuestionTagPicker,
SurveyThemePicker,
       quillEditor,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    surveyInfo:null,
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    questionCount:0,
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
        activeQuestion:null
  },
    mixins: [heightTransition],
  data() {
    return {
      required,
      surveyChecker:0,
      alphaNum,
      email,
      countries,
      minValue: 1,
      currentId:0,
      selectedCategory:null,
      questionTag:null,
      questionTypeKey: '',
      isUpdated:false,
      maxValue:10,
       value: null,
      options: [
       
      ],
         snowOption: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'align': [] }],
        
           
        [{ 'color': [] }, { 'background': [] }],
            ['clean']
          ]
        }
      },
      localQuestion: {
        required: true,
        title: '',
      examid: '',
      description:'',
      questionType: '',
      questionTypeKey: '',
      allowComment:false,
      acceptedAnswers: [],
      acceptedAnswer:null,
      questionAnswers:[{
        id: 1,
        selected:false,
        value: this.generateUUID(),
        answer:null
       
      }],
      additionalInfo: {
      
        min1Value:1,
            min2Value:2,
                min3Value:3,
                    min4Value:4,
                        min5Value:5,

                           min1Text:"Poor",
                              min2Text:"Needs Improvement",
                                 min3Text:"Average",
                                    min4Text:"Good",
                                       min5Text:"Excellent",

          maxValue:5,
          minText:"Bad",
          maxText:"Amazing"
      }
      }
    }
  },
     mounted() {
    this.initTrHeight()
  },
  created() {
    //console.log('created');
    this.questionTag=this.activeQuestion==null?null:this.activeQuestion.questionTag;
    //console.log(this.questionTag);
   window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
   computed: {
    state() {
      return Boolean(this.value)
    },
    answers(){
     
  //console.log("---");
       //console.log(this.localQuestion.questionAnswers);


      return   JSON.parse(JSON.stringify( this.localQuestion.questionAnswers)) ;

    }
  },
  watch: {
   
    activeQuestion(newVal,oldVal){
 

      if(newVal){
        this.localQuestion=newVal;
        //console.log("incoming new wall");
        //console.log(this.localQuestion);

        if  (newVal!=null&&newVal.additionalInfo!=null&&newVal!=undefined&&newVal.additionalInfo!=undefined)  {
         this.additionalInfo= newVal.additionalInfo;
         this.questionTag=newVal.questionTag;

        } else

        {
   this.questionTag=newVal.questionTag;

 this.additionalInfo=    {
      
        min1Value:1,
            min2Value:2,
                min3Value:3,
                    min4Value:4,
                        min5Value:5,
                        min1Text:"Poor",
                              min2Text:"Needs Improvement",
                                 min3Text:"Average",
                                    min4Text:"Good",
                                       min5Text:"Excellent",
          maxValue:5,
          minText:"Bad",
          maxText:"Amazing"
      }  ;
        }
       
        //console.log("question updated");
        //console.log(this.localQuestion);
       this.selectedCategory= this.localQuestion.questionTypeKey;
       
      }
          this.currentId++;
    }
    },
  methods:{

      resetForm(){

    },
      generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
},
 repeateAgain() {

     

      
      this.localQuestion.questionAnswers.push({
       id:1,
        selected: false,
        value:this.generateUUID(),
        answer: '',
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
       var items=this.localQuestion.questionAnswers;

//        var result = items.filter(obj => {
//   return obj.value=== item
// })
 

       items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
       // this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
        handleQuestionSubmit() {
        //console.log("Survey ADD Pquestion  Create triggered");
        var surveyid= store.getters['app/currentUnit'] ;;
     this.localQuestion.surveyid=surveyid;
        
        this.localQuestion.questionOrder=this.questionCount+1;
       //console.log(this.localQuestion);

       if (this.surveyInfo.surveyTypeKey==='LEADERSHIP'||this.surveyInfo.surveyTypeKey==='EVALUATION')
       {
        this.localQuestion.questionType="Range Question";
         this.localQuestion.questionTypeKey="RANGE_SELECTION";
        this.selectedCategory={  'questionType':'Range Question' ,'questionTypeKey':"RANGE_SELECTION"};


       }
     



      store.dispatch('app-survey-questions/addSurveyQuestion', this.localQuestion)
        .then(() => {
          
       this.selectedCategory=null;
        this.userData= { title: '',
        surveyid: '',
      description:'',
      questionType: '',
      questionTypeKey: '',
      allowComment:false,
      acceptedAnswers: [],
      acceptedAnswer:null,
      questionAnswers:[{
        id: 1,
        selected:false,
        value: this.generateUUID(),
        answer:null
       
      }],
     additionalInfo: {
      
        min1Value:1,
            min2Value:2,
                min3Value:3,
                    min4Value:4,
                        min5Value:5,

                        min1Text:"Poor",
                              min2Text:"Needs Improvement",
                                 min3Text:"Average",
                                    min4Text:"Good",
                                       min5Text:"Excellent",

          maxValue:5,
          minText:"Bad",
          maxText:"Amazing"
      }
      };

      this.additionalInfo= {
      
        min1Value:1,
            min2Value:2,
                min3Value:3,
                    min4Value:4,
                        min5Value:5,

                        min1Text:"Poor",
                              min2Text:"Needs Improvement",
                                 min3Text:"Average",
                                    min4Text:"Good",
                                       min5Text:"Excellent",

          maxValue:5,
          minText:"Bad",
          maxText:"Amazing"
      }


         this.$emit('refetch-data')
          this.$emit('update:is-add-new-user-sidebar-active', false)
        })
   
      
    },

    QuestionTagUpdated(payload) {
         //console.log("question result in info ");
      //console.log(payload);
      if (payload==null||payload==""||payload==undefined)  
      {
  
      this.questionTag=null;
     this.questionTagType=null;
          this.localQuestion.questionTag = null,
    this.localQuestion.questionTagKey = null
      } else
    {  
    //console.log("question type  data updated ");
    //console.log(payload.typekey);
    

     this.questionTagType=payload.typekey;
     this.questionTag=payload.title;
      this.questionTag=payload;

       this.localQuestion.questionTag = payload.title,
     this.localQuestion.questionTagType = payload.typekey

           

    }

   
     
  },

      SurveyTypeUpdated(payload) {
         //console.log("question result in info ");
      //console.log(payload);
      if (payload==null)
      {
  
      this.questionTypeKey=null;
     this.questionType=null;
          this.localQuestion.questionType = null,
    this.localQuestion.questionTypeKey = null
      } else
    {  
    //console.log("question type  data updated "+payload.typekey);

     this.questionTypeKey=payload.typekey;
     this.questionType=payload.title;
      this.selectedCategory=payload;

       this.localQuestion.questionType = payload.title,
     this.localQuestion.questionTypeKey = payload.typekey

            if (payload.typekey=="YES_NO_QUESTION")
            {
              this.localQuestion.questionAnswers=
              [
                {
        id: 1,
        selected:false,
        value: 'YES',
        answer:"YES"
       
      },
      {
        id: 2,
        selected:false,
        value: 'NO',
        answer:"NO"
       
      }
              ]

            }

    }

    },
      onEditorBlur(quill) {
    //    //console.log('editor blur!', quill)
      },
      onEditorFocus(quill) {
     //   //console.log('editor focus!', quill)
      },
      onEditorReady(quill) {
   //     //console.log('editor ready!', quill)
      },
      onEditorChange({ quill, html, text }) {
   //     //console.log('editor change!', quill, html, text)
   this.isUpdated=true;
        this.localQuestion.description= html
      }
  },
  setup(props, { emit }) {

    return;
    
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
 
@import '@core/scss/vue/libs/quill.scss';

.ql-editor{
    min-height:100px;
}
</style>
